import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Seo from './seo'
import GlobalStyles from '../styles/global'

export const BodyWrapper = styled.div`
  background-color: ${props => props.bgColor || 'white'};
`

const Layout = ({ children, frontmatter = {}, bgColor, location = {}, subNavFixed }) => (
  <BodyWrapper bgColor={bgColor || frontmatter.body_color}>
    <Seo {...frontmatter} />
    <div>
      <main>{children}</main>
    </div>
    <GlobalStyles />
  </BodyWrapper>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
