import React from 'react'
import Layout from '../components/layoutNoHeaderFooter'
import Logo from '../images/pga-logo.svg'

const MaintenancePage = ({ location, textBlurb }) => {
  return (
    <Layout {...{ location }}>
      <div style={{
        backgroundColor: '#00234b',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
         alignItems: 'center',
         justifyContent: 'center'
        }} className="text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6 mx-auto text-center">
              <img className="mb-4" src={Logo} alt="PGA of America" style={{ width: '30%', height: 'auto', marginBottom: '16px' }} />
              <h2 className="mb-4 text-white">Under Maintenance</h2>
              <p className="mx-auto text-white">{textBlurb}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}



export default MaintenancePage

